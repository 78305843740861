/**
 * Order statuses.
 */
var OrderStatusEnum;
(function (OrderStatusEnum) {
    /** Order created. */
    OrderStatusEnum["Created"] = "CREATED";
    /** Order accepted. */
    OrderStatusEnum["Accepted"] = "ACCEPTED";
    /** Order has been rejected by superior user. */
    OrderStatusEnum["Rejected"] = "REJECTED";
    /** Order was paid.  */
    OrderStatusEnum["Paid"] = "PAID";
    /** Order in shipping process. */
    OrderStatusEnum["Shipped"] = "SHIPPED";
    /** Order in shipping process. */
    OrderStatusEnum["Done"] = "DONE";
    /** Product installed by medianintegrator. */
    OrderStatusEnum["Installed"] = "INSTALLED";
    /** Order has been cancelled by user. */
    OrderStatusEnum["Cancelled"] = "CANCELLED";
    /** Order in error state due to technical error. */
    OrderStatusEnum["Failed"] = "FAILED";
})(OrderStatusEnum || (OrderStatusEnum = {}));
/**
 * Order event statuses.
 * FIXME: @Jan & @Jonas: mögliche werte?
 */
var OrderEventStatusEnum;
(function (OrderEventStatusEnum) {
    OrderEventStatusEnum["MailSent"] = "MAIL_SENT";
    OrderEventStatusEnum["IntegrationExecuted"] = "INTEGRATION_EXECUTED";
})(OrderEventStatusEnum || (OrderEventStatusEnum = {}));
/**
 * Order event statuses.
 * FIXME: @Jan & @Jonas: mögliche werte?
 */
var OrderEventPayloadEnum;
(function (OrderEventPayloadEnum) {
    OrderEventPayloadEnum["OrderConfirmation"] = "ORDER_CONFIRMATION";
    OrderEventPayloadEnum["OrderRejection"] = "ORDER_REJECTION";
    OrderEventPayloadEnum["OrderProcessingConfirmation"] = "ORDER_PROCESSING_CONFIRMATION";
    OrderEventPayloadEnum["OrderShippingConfirmation"] = "ORDER_SHIPPING_CONFIRMATION";
})(OrderEventPayloadEnum || (OrderEventPayloadEnum = {}));
/**
 * Price types.
 */
var PriceTypeEnum;
(function (PriceTypeEnum) {
    PriceTypeEnum["Net"] = "NET";
    PriceTypeEnum["Gross"] = "GROSS";
})(PriceTypeEnum || (PriceTypeEnum = {}));
export { OrderStatusEnum as OrderStatus };
export { OrderEventStatusEnum as OrderEventStatus };
export { OrderEventPayloadEnum as OrderEventPayload };
export { PriceTypeEnum as PriceType };
